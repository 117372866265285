<template>
  <div class="animated fadeIn propiedad-datos">
    <b-row>
      <b-col xl="12">
        <app-data-propiedad/>

        <app-data-caracteristicas/>

        <app-data-imagenes
          @setImagenMain="setImagenMain"
          @borrarImagen="borrarImagen">
        </app-data-imagenes>

        <app-data-comentarios/>
      </b-col>
    </b-row>

    <div class="fixed-btn">
      <div class="list-dropdown-btn">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template slot="button-content">
            <i class="fa fa-ellipsis-v fa-2x" aria-hidden="true"></i>
          </template>

          <b-dropdown-item @click.stop="editar">
            <i class="fa fa-edit"></i>Editar Datos de la Propiedad</b-dropdown-item>

          <!-- <b-dropdown-item @click.stop="agregarImagen">
            <i class="fa fa-plus"></i>Agregar Imagen</b-dropdown-item> -->

          <b-dropdown-item @click.stop="goBack">
            <i class="fa fa-undo"></i>Volver</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <!-- <b-modal ref="imagenModal"
        title="Imagen"
        modal-class="modalABM"
        header-bg-variant= "light" 
        hide-footer
        lazy>
      <app-imagen
        :propiedadId="propiedadId"
        @close="closeImagen">
      </app-imagen>  
    </b-modal> -->

    <app-modal-msj
      :visible="modalMsjVisible"
      :mensaje="modalMensaje"
      :variant="modalVariant"
      @okAction="okAction"
      @hiddenAction="hiddenAction">
    </app-modal-msj>
  </div>
</template>

<script>
import DataPropiedad from './DataPropiedad';
import DataCaracteristicas from './DataCaracteristicas';
import DataImagenes from './DataListImagenes';
// import DataImagenes from './DataImagenes';
import DataComentarios from './DataComentarios';
// import Imagen from './Imagen';

import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "propiedad/getField",
  mutationType: "propiedad/updateField",
});

export default {
  name: 'PropiedadesData',
  components: {
    appDataPropiedad: DataPropiedad,
    appDataCaracteristicas: DataCaracteristicas,
    appDataImagenes: DataImagenes,
    appDataComentarios: DataComentarios,
    // appImagen: Imagen,
  },
  data () {
    return {
      propiedadId: '',
    }
  },
  computed: {
    ...mapFields([
    ]),
  },
  created() {
    if (this.$route.params){
      if (this.$route.params.id){
        this.propiedadId = this.$route.params.id;
      }
    }

    this.getDatosPropiedad();
  },
  methods: {
    getDatosPropiedad() {
      this.$store.dispatch('propiedad/getPropiedad', this.propiedadId);
    },
    editar() {
      this.$router.push({ name: 'Propiedad', 
                    params: { id: this.propiedadId }});
    },
    // agregarImagen () {
    //   this.$refs.imagenModal.show();
    // },
    // closeImagen(imagenActualizada) {
    //   this.$refs.imagenModal.hide();
    //   // if ((/true/i).test(imagenActualizada)) {
    //   //   this.getDatosPropiedad()
    //   // }
    // },
    setImagenMain (imagenId) {
      let params = {
        propiedadId: this.propiedadId,
        imagenId: imagenId
      }
      
      this.showSpinner();
      this.$store.dispatch('propiedad/setMainImagen', params)
        .then(() => this.getDatosPropiedad)
        .catch(error => this.showErrorMsj(error))
        .finally(() => this.hideSpinner());
    },
    borrarImagen (imagenId) {
      let params = {
        propiedadId: this.propiedadId,
        imagenId: imagenId
      }
      
      this.showSpinner();
      this.$store.dispatch('propiedad/deleteImagen', params)
        .then(() => this.getDatosPropiedad)
        .catch(error => this.showErrorMsj(error))
        .finally(() => this.hideSpinner());
    },

    goBack() {
      this.$router.push({ name: 'Propiedades' });
      // this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/users'})
    }
  },
}
</script>
