<template>
  <b-card header="Mensajes sobre la Propiedad" class="card-filter">
    <b-btn class="filter-collapse" 
        variant="primary" 
        size="sm"
        @click.prevent="showDatos= !showDatos">
      <i :class="showDatos ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
    </b-btn>
    <b-collapse id="comentarios" class="mt-2 filter-body" v-model="showDatos">
      <b-table hover
          responsive="sm" 
          class="registro comentarios"
          :busy="comentariosTableBusy"
          :items="comentarios" 
          :fields="comentariosFields"
          :current-page="comentariosPaginaActual"
          :per-page="comentariosRegistrosPorPagina">
          <!-- @row-clicked="consultar"> -->
        <div slot="table-busy" class="text-center">
          <b-spinner class="table-spinner" label="Spinning" />
        </div>

        <template slot="fechaRegistro" slot-scope="data">
          {{ data.item.fechaRegistro | fechaHora }}
        </template>
        <template slot="fechaLeido" slot-scope="data">
          {{ data.item.fechaLeido | fechaHora }}
        </template>

        <!-- <template slot="opciones" slot-scope="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template slot="button-content">
              <i class="fa fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item @click.stop="borrarImagen(data.item)">
              <i class="fa fa-edit"></i>Borrar Imagen</b-dropdown-item>
          </b-dropdown>
        </template> -->
      </b-table>
      <nav>
        <b-pagination size="sm" 
          :total-rows="comentariosTotalRegistros" 
          :per-page="comentariosRegistrosPorPagina" 
          v-model="comentariosPaginaActual" prev-text="Prev." next-text="Prox."/>
      </nav>
    </b-collapse>
  </b-card>
</template>

<script>
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "propiedad/getField",
  mutationType: "propiedad/updateField",
});

export default {
  name: 'PropiedadeDatosComentarios',
  components: {
  },
  mixins: [ 
  ],
  data () {
    return {
      showDatos: false,

      comentariosFields: [
        { 
          key: 'fechaRegistro',
          label: 'Fecha'
        },
        { key: 'nombre' },
        { key: 'email' },
        { key: 'telefono' },
        { key: 'mensaje' },
        { key: 'fechaLeido' },
        // { key: 'status' }
      ],
      comentariosTableBusy: false,

      labelCols: 2,
    }
  },
  computed: {
    ...mapFields([
	    'comentarios',
      'comentariosPaginaActual',
      'comentariosTotalPaginas',
      'comentariosRegistrosPorPagina',
      'comentariosTotalRegistros',
    ]),
  },
  created() {
  },
  methods: {
  },
}
</script>

<style>
  .propiedad-datos .comentarios table th {
    border-top: none;
  }
  .propiedad-datos .comentarios table td[aria-colindex="1"] {
    width: 120px;
  }
  .propiedad-datos .comentarios table td[aria-colindex="2"] {
    width: 15%;
  }
  .propiedad-datos .comentarios table td[aria-colindex="3"] {
    width: 15%;
  }
  .propiedad-datos .comentarios table td[aria-colindex="4"] {
    width: 15%;
  }
  .propiedad-datos .comentarios table td[aria-colindex="5"] {
    width: calc(55% - 240px);
  }
  .propiedad-datos .comentarios table td[aria-colindex="6"] {
    width: 120px;
  }
</style>