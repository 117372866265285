<template>
  <b-card header="Características Adicionales" class="card-filter">
    <b-btn class="filter-collapse" 
        variant="primary" 
        size="sm"
        @click.prevent="showDatos= !showDatos">
      <i :class="showDatos ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
    </b-btn>
    <b-collapse id="datos" class="mt-2 filter-body" v-model="showDatos">
      <b-form>
        <template v-for="caracteristica, key in caracteristicas">
          
          <p :key="key" v-if="caracteristica.checked"><i class="fa fa-check"></i> {{ caracteristica.nombre }} </p>
        </template>
      </b-form>
    </b-collapse>
  </b-card>
</template>

<script>
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "propiedad/getField",
  mutationType: "propiedad/updateField",
});

export default {
  name: 'PropiedadDatosPropiedad',
  data () {
    return {
      showDatos: false,
      labelCols: 2
    }
  },
  computed: {
    ...mapFields([
      'caracteristicas',
    ]),
  },
  created() {
  },
  methods: {
  },
}
</script>
