<template>
  <b-card header="Información de la Propiedad" class="card-filter">
    <b-btn class="filter-collapse" 
        variant="primary" 
        size="sm"
        @click.prevent="showDatos= !showDatos">
      <i :class="showDatos ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
    </b-btn>
    <b-collapse id="datos" class="mt-2 filter-body" v-model="showDatos">
      <b-form>
        <b-form-group
          label="Tipo de propiedad"
          label-for="tipoPropiedad"
          :label-cols="labelCols">
          <b-form-input 
            id="tipoPropiedad"
            :value="tipoPropiedadNombre"
            readonly>
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Titulo"
          label-for="titulo"
          :label-cols="labelCols">
          <b-form-input 
            id="titulo"
            :value="titulo"
            readonly>
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Descripcion"
          label-for="descripcion"
          :label-cols="labelCols">
          <b-form-textarea
            id="descripcion"
            :value="descripcion"
            rows="5"
            max-rows="5"
            readonly>
          </b-form-textarea>
          <!-- <b-form-input 
            id="descripcion"
            :value="descripcion"
            readonly>
          </b-form-input> -->
        </b-form-group>

        <b-form-group
          label="Dirección"
          label-for="direccion"
          :label-cols="labelCols">
          <b-form-input 
            id="direccion"
            :value="direccion"
            readonly>
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Localidad"
          label-for="localidad"
          :label-cols="labelCols">
          <b-form-input 
            id="localidad"
            :value="localidad"
            readonly>
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Barrio"
          label-for="barrio"
          :label-cols="labelCols">
          <b-form-input 
            id="barrio"
            :value="barrio"
            readonly>
          </b-form-input>
        </b-form-group>

        <div class="grupo-controles-location">
          <b-form-group
            label="Ubicación"
            label-for="location"
            :label-cols="2"
            description="Latitud + Longitud"
            horizontal>
            <b-form-input 
              id="locationLat"
              :value="locationLat"
              readonly/>
            <b-form-input 
              id="locationLng"
              :value="locationLng"
              readonly/>
          </b-form-group>
        </div>

        <!-- <b-form-group
          label="Lat."
          label-for="locationLat"
          :label-cols="labelCols">
          <b-form-input 
            id="locationLat"
            :value="locationLat"
            readonly>
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Lng."
          label-for="locationLng"
          :label-cols="labelCols">
          <b-form-input 
            id="locationLng"
            :value="locationLng"
            readonly>
          </b-form-input>
        </b-form-group> -->

        <b-form-group
          label="Sup.Total"
          label-for="superficieTotal"
          :label-cols="labelCols"
          description="Superficie en m2">
          <b-form-input 
            id="superficieTotal"
            :value="superficieTotal"
            readonly>
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Sup.Cubierta"
          label-for="superficieCubierta"
          :label-cols="labelCols"
          description="Superficie en m2">
          <b-form-input 
            id="superficieCubierta"
            :value="superficieCubierta"
            readonly>
          </b-form-input>
        </b-form-group>

        <!-- <b-form-group
          label="A Estrenar"
          label-for="aEstrenar"
          :label-cols="labelCols">
          <b-form-input 
            id="aEstrenar"
            :value="aEstrenar? 'Si': 'No'"
            readonly>
          </b-form-input>
        </b-form-group> -->

        <b-form-group
          label="Antiguedad"
          label-for="antiguedad"
          :label-cols="labelCols"
          description="Antigüedad en años">
          <b-form-input 
            id="antiguedad"
            :value="aEstrenar? 'A Estrenar' : antiguedad"
            readonly>
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Ambientes"
          label-for="ambientes"
          :label-cols="labelCols">
          <b-form-input 
            id="ambientes"
            :value="ambientes"
            readonly>
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Dormitorios"
          label-for="dormitorios"
          :label-cols="labelCols">
          <b-form-input 
            id="dormitorios"
            :value="dormitorios"
            readonly>
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Baños"
          label-for="banios"
          :label-cols="labelCols">
          <b-form-input 
            id="banios"
            :value="banios"
            readonly>
          </b-form-input>
        </b-form-group>

        <div class="grupo-controles-precio-readonly">
          <b-form-group
            label="Precio Venta"
            label-for="venta"
            :label-cols="2"
            horizontal>
            <b-form-input 
              id="monedaVenta"
              :value="monedaVenta" 
              readonly>
            </b-form-input>
            <b-form-input 
              id="precioVenta"
              :value="precioVenta" 
              readonly>
            </b-form-input>
          </b-form-group>
        </div>

        <div class="grupo-controles-precio-readonly">
          <b-form-group
            label="Precio Alquiler"
            label-for="alquiler"
            :label-cols="2"
            horizontal>
            <b-form-input 
              id="monedaAlquiler"
              :value="monedaAlquiler" 
              readonly>
            </b-form-input>
            <b-form-input 
              id="precioAlquiler"
              :value="precioAlquiler" 
              readonly>
            </b-form-input>
          </b-form-group>
        </div>

        <b-form-group
          label="Expensas"
          label-for="expensas"
          :label-cols="labelCols">
          <b-form-input 
            id="expensas"
            :value="expensas"
            readonly>
          </b-form-input>
        </b-form-group>

        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Destacada"
              label-for="destacada"
              :label-cols="labelCols * 2">
              <b-form-input 
                id="destacada"
                :value="destacada? 'Si': 'No'"
                readonly>
              </b-form-input>
            </b-form-group>

            <b-form-group
              label="Publicada"
              label-for="publicada"
              :label-cols="labelCols * 2">
              <b-form-input 
                id="publicada"
                :value="publicada? 'Si': 'No'"
                readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-collapse>
  </b-card>
</template>

<script>
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "propiedad/getField",
  mutationType: "propiedad/updateField",
});

export default {
  name: 'PropiedadDatosPropiedad',
  data () {
    return {
      showDatos: true,
      labelCols: 2
    }
  },
  computed: {
    ...mapFields([
	    'tipoPropiedadNombre',
	    'titulo',
	    'descripcion',
	    'direccion',
	    'localidad',
	    'barrio',
	    'locationLat',
      'locationLng',
	    'superficieTotal',
	    'superficieCubierta',
	    'antiguedad',
	    'ambientes',
	    'dormitorios',
	    'banios',
	    'monedaVenta',
	    'precioVenta',
	    'monedaAlquiler',
	    'precioAlquiler',
	    'expensas',
      'aEstrenar',
      'destacada',
      'publicada',
    ]),
  },
  created() {
  },
  methods: {
  },
}
</script>

<style scoped>
  /* .grupo-controles-precio {
    display: inline-block;
  } */
</style>