<template>
  <b-card class="card-filter">
    <div slot="header">Imagenes de la Propiedad 

      <div class="absolute-positioned-btn" v-if="showDatos">
        <div class="card-add-btn" @click.prevent="agregarImagenes">
            <i class="fa fa-plus" aria-hidden="true"></i>
        </div>
      </div>      

      <!-- <b-button 
          size="sm" 
          variant="primary" 
          class="propiedad-file-button"
        @click.prevent="agregarImagenes">Agregar Imagenes</b-button> -->
    </div>
    <b-btn class="filter-collapse" 
        variant="primary" 
        size="sm"
        @click.prevent="showDatos= !showDatos">
      <i :class="showDatos ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
    </b-btn>

    <b-collapse id="imagenes" class="mt-2 filter-body" v-model="showDatos">
      <b-row v-if="seleccionImagenes">
        <b-col>
          <b-form>
            <b-form-group
              description="Imagenes (jpg, png o jpeg) de hasta 300KB">
              <b-form-file 
                v-model="files" 
                class="mt-3" 
                accept=".jpg, .png, .jpeg" 
                placeholder="Seleccione una o más imágenes o arrastrelas hasta aquí"
                multiple>
              </b-form-file>
              <b-input-group-append 
                  class="filter-search-icons" 
                  @click="agregarImagenes">
                <b-input-group-text>
                  <i class="fa fa-remove"></i>
                </b-input-group-text>
              </b-input-group-append>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>

      <b-row>
        <template
          v-for="{
            _id,
            url,
            main,
            key } in imagenes">
          <b-col :key="key" cols="12" md="4">
            <b-card no-header class="propiedad-imagen">
              <b-img :src="url" fluid></b-img>
              <p v-if="main">Imagen principal</p>
              <div class="footer">
                <b-button size="sm" variant="primary" class="footer-button"
                  :disabled="main"
                  @click.prevent="setImagenMain(_id)">Principal</b-button>
                <b-button size="sm" variant="danger" class="footer-button" 
                  @click.prevent="borrarImagen(_id)">Borrar</b-button>
              </div>
          </b-card>
          </b-col>
        </template>
      </b-row>

      <!-- <b-row v-if="!seleccionImagenes">
        <b-col>
          <b-button 
              size="sm" 
              variant="primary" 
              class="footer-button propiedad-file-button"
            @click.prevent="agregarImagenes">Agregar Imagenes</b-button>
        </b-col>
      </b-row> -->
    </b-collapse>

    <app-modal-msj
      :visible="modalMsjVisible"
      :mensaje="modalMensaje"
      :variant="modalVariant"
      @okAction="okAction"
      @hiddenAction="hiddenAction">
    </app-modal-msj>
  </b-card>
</template>

<script>
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "propiedad/getField",
  mutationType: "propiedad/updateField",
});

export default {
  name: 'PropiedadDatosImagenes',
  data () {
    return {
      showDatos: false,
      
      imagenesFields: [
        { key: 'imagen' },
        { 
          key: 'main',
          label: 'Destacada'
        },
        { 
          key: 'opciones',
          label: '',
          class: 'text-center'
        },
      ],
      imagenesTableBusy: false,

      files: [],
      uploaded: [],
      seleccionImagenes: false,
    }
  },
  computed: {
    ...mapFields([
      'id',
	    'imagenes',
      'imagenesPaginaActual',
      'imagenesTotalPaginas',
      'imagenesRegistrosPorPagina',
      'imagenesTotalRegistros',
    ]),
  },
  created() {
  },
  methods: {
    setImagenMain (imagenId) {
      this.$emit('setImagenMain', imagenId);
    },
    borrarImagen (imagenId) {
      this.$emit('borrarImagen', imagenId);
    },
    agregarImagenes() {
      this.seleccionImagenes = !this.seleccionImagenes;
    },
    uploadFiles() {
      if (this.files) {
        if (this.files.length != 0) {
          this.uploaded = [];

          this.showSpinner();

          let isMain = true;

          if (this.imagenes) {
            if (this.imagenes.length != 0) {
              isMain = false;
            }
          }

          this.files.forEach(file => {
            let params = {
              propiedadId: this.id,
              file: file,
              main: isMain
              // main: this.main
            }

            /* Marcamos la primera como principal */
            isMain = false;

            this.uploaded.push(file);

            this.$store.dispatch('propiedad/addImagen', params)
              .then( () => this.fileUploaded(file))
              .catch( error => {
                this.hideSpinner();
                this.showErrorMsj (error);
              });
          });
        }
      }
    },
    fileUploaded(file) {
      console.log('Uploaded');

      let index = this.uploaded.findIndex(uploadedFile => uploadedFile.name == file.name);

      if (index != -1) {
        this.uploaded.splice(index, 1);

        if (this.uploaded.length == 0) {
          this.files = [];
          this.seleccionImagenes = false;
          this.hideSpinner();
        }
      }
    },
  },
  watch: {
    'files' : function(newVal, oldVal) {
      this.uploadFiles();
    },    
  }    
}
</script>

<style scoped>
  .propiedad-imagen {
    border: none;
    padding-top: 10px;
    text-align: center;
  }
  .propiedad-imagen img {
    margin-bottom: 10px;
  }
  .propiedad-imagen .footer {
    min-height: 40px;
  }
  .propiedad-imagen .footer button {
    display: none;
    margin-right: 0;
  }
  .propiedad-imagen:hover .footer button {
    display: inline-block;
  }
  @media(max-width:766px){
    .propiedad-imagen .footer button {
      display: inline-block;
    }
  }

  /* .propiedad-imagen .footer button {
    display: inline-block;
    margin-right: 0;
  } */
  .propiedad-imagen .footer button:first-of-type {
    width: calc(50% - 5px);
    margin-right: 10px;
  }
  .propiedad-imagen .footer button:last-of-type {
    width: calc(50% - 5px);
  }
  .propiedad-imagen button.disabled {
    cursor: default; 
  }

  .propiedad-datos .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: 'Buscar' !important;
  }

  .propiedad-datos .b-form-file {
    position: relative;
    display: inline-block;
    width: calc(100% - 50px);
  }

  .propiedad-datos .filter-search-icons {
    position: relative;
    display: inline-block;
    margin-left: 5px;
    margin-top: 6px;
    /* margin-top: 16px; */
    /* width: calc(100% - 50px); */
  }
  .propiedad-datos .filter-search-icons div {
    height: 34px;
  }

  .card-header .propiedad-file-button {
    position: relative;
    right: 0;
  }

  /* .propiedad-file-button {
    position: relative;
    width: 100%;
    height: 25px;
    padding-top: 2px;
    padding-bottom: 2px;
  } */
</style>